<template>

<div class="min-h-screen flex justify-between flex-col">
  <Header/>

  <div class="max-w-[1500px] mx-auto">
    <h1 v-if="error.statusCode === 404" class="text-darkRed font-bold">Page not found</h1>
    <div v-else>
      <div class="flex flex-col min-h-[40vh] my-5 px-4 relative text-center">
        <h1 class="mr-2 opacity-20 text-3xl">Page not found</h1>
        <h2 class="text-3xl opacity-60">{{ error.statusCode }}</h2>
        <p class="mb-5 text-3xl">This can happen because either the page URL has changed or because of a mistype</p>
        <p class="mb-5 text-4xl">ARE YOU LOOKING FOR HORSE SHOW RESULTS?</p>
        <div class="flex flex-col  lg:w-auto">
          <div class="">
            <div class="relative text-black focus-within:text-black max-h-10">
                           <span class="absolute inset-y-0 right-0 flex items-center px-2">
                              <button id="search" @click="submitSearch()" name="search" type="submit" class="p-1 focus:outline-none focus:shadow-outline" aria-label="search">
                                 <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" class="w-6 h-6">
                                    <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                                 </svg>
                              </button>
                           </span>
              <input type="search" name="q" v-model="search" @keyup.enter="submitSearch()" class="placeholder-custom max-h-8 py-2 text-lg text-black border
                   focus:ring-violet-300 border-neutral-200 pl-4 pr-12 focus:ring-1 focus:shadow-inner focus:outline-none focus:text-black w-full"
                     placeholder="Search" autocomplete="off"
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <Footer/>
</div>

</template>
<script setup>

import {ref} from 'vue'

const props = defineProps({
  error: {
    type: Object,
    required: true
  }
})

const search = ref('');
const router = useRouter();

const submitSearch = () => {
  router.push('/search-results?search='+search.value).then(() => { router.go() });
}


</script>
